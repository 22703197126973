import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const OdsContainer = styled.div`
    height: 170px;
    background: #fff;
    display: flex;
    padding-left: calc((100vw - 1000px) /2);
    padding-right: calc((100vw - 1000px) /2);

    @media screen and (max-width: 768px){
      height: auto;
    }
`;

export const OdsMainRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: '20% 80%';
    grid-template-areas: 'col1 col2';
    
    @media screen and (max-width: 768px){
        grid-template-areas: 'col1' 'col2';
    }
`;

export const OdsImageContainer = styled.div`
  background: #fff;

  @media screen and (max-width: 768px){    
    margin-left: 24px;
  }
`;

export const Image = styled.img`
  background: ${props => props.color};
  width: 80px;
  height: 80px;
  margin: 5px;

  @media screen and (max-width: 768px){
    width: 50px;
    height: 50px;
    margin: 2px;
  }
`;

export const RelatoryContainer = styled.div`
    height: 200px;
    background: #0397D9;
    display: flex;
    padding-left: calc((100vw - 1000px) / 2);
    padding-right: calc((100vw - 1000px) / 2);

    @media screen and (max-width: 768px){
      height: auto;
      margin-top: 5px;
    }
`;

export const MainRow = styled.div`
    width: 100%;
    display: grid;
    //grid-auto-columns: minmax(auto, 1fr);
    //grid-template-columns: '60% 40%';
    //align-items: center;
    grid-template-areas: ${(grid) => (grid ? `'col1 col2 col2'` : `'col1 col1 col2'`)};
    
    @media screen and (max-width: 768px){
        grid-template-areas: 'col1' 'col2';
    }
`;

export const Column1 = styled.div`
    //margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    grid-area: col1;
    //background: green;
    height: 200px;

    @media screen and (max-width: 768px){
      height: auto;
      justify-content: left;
    }
`;

export const Column2 = styled.div`
    //margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    grid-area: col2;
    //background: red;
    height: 200px;

    @media screen and (max-width: 768px){
      height: auto;
      justify-content: left;
      padding: 0;
    }
`;

export const BtnLink = styled(Link)`
  border-radius: 5px;
  background: #fff;
  padding: 6px 12px;
  max-width: 150px;
  max-height: 62px;
  text-align: center;
  color: #FD6924;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  margin-left: 24px;

  &:hover {
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 768px){
    max-width: 185px;
    padding: 8px 12px;
    margin-bottom: 16px;
  }
`;

export const ContainerParagraph = styled.div`
  margin: 35px;

  @media screen and (max-width: 768px){
    margin: 10px;
  }
`;

export const Paragraph = styled.p`
  color: ${props => props.color || '#fff'};
  font-size: ${props => props.size};
  font-weight: ${props => props.fontWeight || 'lighter'};
  margin-bottom: ${props => props.margin || '0'};
  padding-top: ${props => props.paddingTop || '0'};
  padding-bottom: ${props => props.paddingBottom || '0'};
  padding-left: ${props => props.paddingLeft || '0'};

  @media screen and (max-width: 768px){
    font-size: 16px;
  }
`; 