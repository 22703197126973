import styled from 'styled-components';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
    Checkbox,
    Stack 
  } from "@chakra-ui/react"

export const RelatorioContainer = styled.nav`
  background: #fff;
  display: flex;
  padding-left: calc((100vw - 1000px) / 2);
  padding-right: calc((100vw - 1000px) / 2);
  margin-top: 15px;
`;

export const RelatorioRow = styled.div`
    width: 100%;
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    //align-items: center;
    grid-template-areas: 'col1 col2';
    
    @media screen and (max-width: 768px){
        grid-template-areas: 'col1' 'col2';
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    //align-items: center;
    //justify-content: center;
    padding: 0 15px;
    grid-area: col1;
    //background: green;
    height: auto;

    @media screen and (max-width: 768px){
      height: auto;
      margin-bottom: 0px;
    }
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    //align-items: center;
    //justify-content: center;
    padding: 0 15px;
    grid-area: col2;
    //background: red;
    height: auto;

    @media screen and (max-width: 768px){
      height: auto;
      margin-bottom: 0px;
    }
`;

export const AccordionContainer = styled(Accordion)`
    width: 100%;
`;

export const AccordionItemOds = styled(AccordionItem)`
    margin-bottom: 10px;
    border-style: none;
`;

/*export const AccordionItemHeadingOds = styled(AccordionItemHeading)`
    border-radius: 10px;
    background: blue;
    height: 40px;
    display: flex;
    align-items: center;
`;*/

export const AccordionButtonOds = styled(AccordionButton)`
    cursor: pointer;
    padding-left: 15px;
    width: 100%;
    text-decoration: none;
    outline: none;
    background: ${props => props.color};
    height: 50px;
    border-radius: 5px;
    outline: none;
`;

export const AccordionPanelOds = styled(AccordionPanel)`
    background: #F1F1F1;
    border-radius: 5px;
`;

export const AccordionBox = styled(Box)`
    color: #fff;
    &:hover {
        color: #000;
    }
`;

export const AccordionCheckbox = styled(Checkbox)`

`;

export const Stack1 = styled(Stack)`` 