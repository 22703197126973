import React from 'react';
import Header from '../components/Header';
import Relatory from '../components/Relatorio';
import Image from '../images/header/pagina_relatorio.jpg'
import { ChakraProvider } from "@chakra-ui/react"

const dados = {image: Image, alt: 'Relatório', text:''}

const Relatorio = () => {
  return (
    <>
      <ChakraProvider>
        <Header {...dados} />
        <Relatory />
      </ChakraProvider>
    </>
  );
};

export default Relatorio;
