import React from 'react'
import { 
    ods1, ods2, ods3, ods4, ods5, ods6, ods7, ods8, ods9,
    ods10, ods11, ods12, ods13, ods14, ods15, ods16, ods17
} from '../../data/relatorioData'
import {
    RelatorioContainer,
    RelatorioRow,
    Column1,
    Column2,
    AccordionContainer,
    AccordionItemOds,
    AccordionButtonOds,
    AccordionPanelOds,
    AccordionBox,
    AccordionCheckbox,
    Stack1 
} from './RelatorioElements'

const coluna1 = [ods1,ods3,ods5,ods7,ods9,ods11,ods13,ods15,ods17];
const coluna2 = [ods2,ods4,ods6,ods8,ods10,ods12,ods14,ods16]

const Relatorio = () => {
    return (
        <>
            <RelatorioContainer>
                <RelatorioRow>
                    <Column1>
                        

                        <AccordionContainer allowMultiple>
                            {coluna1.map((item,i) =>
                                <AccordionItemOds>
                                    <AccordionButtonOds color={item.color}>
                                        <AccordionBox flex="1" textAlign="left">
                                            {item.text}
                                        </AccordionBox>
                                    </AccordionButtonOds>
                                    
                                    <AccordionPanelOds pb={4}>
                                        <Stack1 spacing={2} direction="column">
                                            <AccordionCheckbox>Opção 1</AccordionCheckbox>
                                            <AccordionCheckbox>Opção 2</AccordionCheckbox>
                                            <AccordionCheckbox>Opção 3</AccordionCheckbox>
                                            <AccordionCheckbox>Opção 4</AccordionCheckbox>
                                            <AccordionCheckbox>Opção 5</AccordionCheckbox>
                                        </Stack1>
                                    </AccordionPanelOds>
                                </AccordionItemOds>
                            )}
                            
                        </AccordionContainer>
                    </Column1>
                    <Column2>

                        <AccordionContainer allowMultiple>
                            {coluna2.map((item,i) =>
                                <AccordionItemOds>
                                    <AccordionButtonOds color={item.color}>
                                        <AccordionBox flex="1" textAlign="left">
                                            {item.text}
                                        </AccordionBox>
                                    </AccordionButtonOds>
                                    
                                    <AccordionPanelOds pb={4}>
                                        <Stack1 spacing={2} direction="column">
                                            <AccordionCheckbox>Opção 1</AccordionCheckbox>
                                            <AccordionCheckbox>Opção 2</AccordionCheckbox>
                                            <AccordionCheckbox>Opção 3</AccordionCheckbox>
                                            <AccordionCheckbox>Opção 4</AccordionCheckbox>
                                            <AccordionCheckbox>Opção 5</AccordionCheckbox>
                                        </Stack1>
                                    </AccordionPanelOds>
                                </AccordionItemOds>
                            )}
                        </AccordionContainer>    


                        {/*<AccordionContainer allowMultipleExpanded preExpanded={['a', 'b']}>
                            <AccordionItemOds uuid='a'>
                                <AccordionItemHeadingOds>
                                    <AccordionItemButtonOds>
                                        What harsh truths do you prefer to ignore?
                                    </AccordionItemButtonOds>
                                </AccordionItemHeadingOds>
                                <AccordionItemPanelOds>
                                    <p>
                                        Exercitation in fugiat est ut ad ea cupidatat ut in
                                        cupidatat occaecat ut occaecat consequat est minim minim
                                        esse tempor laborum consequat esse adipisicing eu
                                        reprehenderit enim.
                                    </p>
                                </AccordionItemPanelOds>
                            </AccordionItemOds>
                            <AccordionItemOds uuid='b'>
                                <AccordionItemHeadingOds>
                                    <AccordionItemButtonOds>
                                        Is free will real or just an illusion?
                                    </AccordionItemButtonOds>
                                </AccordionItemHeadingOds>
                                <AccordionItemPanelOds>
                                    <Checkbox defaultIsChecked>Checkbox</Checkbox>
                                </AccordionItemPanelOds>
                            </AccordionItemOds>
                        </AccordionContainer>*/}
                    </Column2>
                </RelatorioRow>
            </RelatorioContainer>
        </>
    )
}

export default Relatorio
