import React from 'react';
import Header from '../components/Header'
import Image from '../images/header/pagina_inicial.jpg'
import Main from '../components/Main'

const dados = {image: Image, alt: 'ODSs', text:''}

const Home = () => {
  return (
    <>
      <Header {...dados} />
      <Main />
    </>
  );
};

export default Home;
