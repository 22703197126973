import React from 'react'
import {
    OdsContainer,
    OdsMainRow,
    RelatoryContainer,
    MainRow,
    OdsImageContainer,
    Image,
    Column1,
    Column2,
    BtnLink,
    ContainerParagraph,
    Paragraph
} from './MainElements'

const Main = () => {
    return (
        <>
            <OdsContainer>
                <OdsMainRow>
                    <Column1>
                        <Paragraph 
                            color='#0397D9' 
                            fontWeight='bold' 
                            size='22px' 
                            paddingTop={window.innerWidth > 768 ? 0 : '12px'}
                            paddingBottom={window.innerWidth > 768 ? 0 : '12px'}
                            paddingLeft={window.innerWidth > 768 ? 0 : '12px'}
                        >ODSs mais acessadas</Paragraph>
                    </Column1>
                    <Column2>
                        <OdsImageContainer>
                            <Image color="#e5233d" src={require('../../images/navbar/ods1.png')} alt='ods1' />
                            <Image color="#4ca146" src={require('../../images/navbar/ods3.png')} alt='ods3' />
                            <Image color="#27bfe6" src={require('../../images/navbar/ods6.png')} alt='ods6' />
                            <Image color="#fbc412" src={require('../../images/navbar/ods7.png')} alt='ods7' />
                            <Image color="#de1768" src={require('../../images/navbar/ods10.png')} alt='ods10' />
                            <Image color="#407f46" src={require('../../images/navbar/ods13.png')} alt='ODS 13' />
                        </OdsImageContainer>
                    </Column2>
                </OdsMainRow>
            </OdsContainer>
            <RelatoryContainer>
                <MainRow>
                    <Column1>
                        <ContainerParagraph>
                            <Paragraph size='22px' margin='20px' fontWeight='bold'>Faça um relatório personalizado</Paragraph>
                            <Paragraph size='16px'>Escolha as ODSs, dados e cidade que você precisa e gere seu relatório personalizado</Paragraph>
                        </ContainerParagraph>
                    </Column1>
                    <Column2>
                        <BtnLink to='/relatorio'> GERAR RELATÓRIO </BtnLink>
                    </Column2>
                </MainRow>
            </RelatoryContainer>  
        </>
    )
}

export default Main
