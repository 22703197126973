import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'
import {Link as LinkR} from 'react-router-dom'
import {FaTimes} from 'react-icons/fa'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #fff;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`

export const SideLinkLogo = styled(LinkR)`
    color: #02689D;
    position: absolute;
    top: 1.2rem;
    left: calc(100vw / 2 - 63px);
    text-decoration: none;
    cursor: pointer;
`;

export const CloseIcon = styled(FaTimes)`
    color: #02689D;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const SidebarWrapper = styled.div`
    color: #02689D;
`

export const SidebarMenu = styled.ul`
    margin-top: 30px;
`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #02689D;
    cursor: pointer;
    margin-top: 5px;

    &:hover{
        color: #01bf71;
        transition: 0.2s ease-in-out;
    }
`

/*export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`*/

export const SidebarRoute = styled(LinkR)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #02689D;
    cursor: pointer;
    margin-top: 5px;

    &:hover{
        color: #01bf71;
        transition: 0.2s ease-in-out;
    }
`
/* ======================================= */
export const SideBtnContainer = styled.div`
    text-align: center;
    //justify-self: flex-center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
`;

export const SideSpan = styled.span`
    text-align: center;
    font-size: 1.5rem;
    color: #02689D;
    margin-bottom: 4px;
`;

export const SideSelectContainer = styled.nav`
    display: flex;
    justify-content: center;
`;

export const SideSelect = styled.select`
    border-radius: 5px;
    background: #256ce1;
    padding: 4px 12px;
    font-size: 1.5rem;
    color: #fff;
    border: none;
    //cursor: pointer;
    //transition: all 0.2s ease-in-out;
    //text-decoration: none;

    /*option {
        color: black;
        background: white;
        display: flex;
        white-space: pre;
        min-height: 20px;
        padding: 0px 2px 1px;
    }*/
`;

export const SideLinkContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    //padding-left: calc((100vw - 270px) / 2);
    //padding-right: calc((100vw - 270px) / 2);
    margin-left: 10px;
    margin-right: 10px;
`;

export const Image = styled.img`
    background: ${props => props.color || '#000'};
    width: 55px;
    height: 55px;
    margin: 2px;
`;

export const SideLink = styled(LinkR)`
    color: #fff;
    text-decoration: none;
    cursor: pointer;
`;

export const InfoTitleContainer = styled.div`
    align-items: center;
`;

export const InfoTitle = styled.p`
    font-size: 18px;
    color: #02689D;
    text-align: center;
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
`;