export const ods1 = {
    text: "1 - Erradicação da Pobreza",
    color: "#e5233d"
}

export const ods2 = {
    text: "2 - Fome Zero e Agricultura Sustentável",
    color: "#dda73a"
}

export const ods3 = {
    text: "3 - Saúde e Bem-estar",
    color: "#4ca146"
}

export const ods4 = {
    text: "4 - Educação de Qualidade",
    color: "#c7212f"
}

export const ods5 = {
    text: "5 - Igualdade de Gênero",
    color: "#ef402d"
}

export const ods6 = {
    text: "6 - Água Potavel e Saneamento",
    color: "#27bfe6"
}

export const ods7 = {
    text: "7 - Energia Limpa e Acessível",
    color: "#fbc412"
}

export const ods8 = {
    text: "8 - Trabalho Decente e Crescimento Econômico",
    color: "#a31c44"
}

export const ods9 = {
    text: "9 - Industria Inovação e Infraestrutura",
    color: "#f26a2e"
}

export const ods10 = {
    text: "10 - Redução das Desigualdades",
    color: "#de1768"
}

export const ods11 = {
    text: "11 - Cidades e Comunidades Sustentáveis",
    color: "#f89d2a"
}

export const ods12 = {
    text: "12 - Consumo e Produção Responsaveis",
    color: "#bf8d2c"
}

export const ods13 = {
    text: "13 - Ação Contra a Mudança Global do Clima",
    color: "#407f46"
}

export const ods14 = {
    text: "14 - Vida na Água",
    color: "#1f97d4"
}

export const ods15 = {
    text: "15 - Vida Terrestre",
    color: "#59ba47"
}

export const ods16 = {
    text: "16 - Paz, Justiça e Instituições eficazes",
    color: "#136a9f"
}

export const ods17 = {
    text: "17 - Parcerias e Meios de Implementação",
    color: "#14496b"
}