import styled from 'styled-components';

export const TitleContainer = styled.div`
    height: 86px;
    background: ${props => props.color || '#0397D9'};
    display: flex;
    align-items: center;
    padding-left: calc((100vw - 1000px) / 2);
    padding-right: calc((100vw - 1000px) / 2);

    @media screen and (max-width: 768px){
      height: 55px;
    }
`;

export const Title = styled.p`
  color: ${props => props.color || '#fff'};
  font-size: ${props => props.size || '36px'};
  font-weight: ${props => props.fontWeight || 'lighter'};
  margin-bottom: ${props => props.margin || '0'};

  @media screen and (max-width: 768px){
    font-size: 20px;
    padding-left: 15px;
  }
`;

export const InfoContainer = styled.div`
    height: 400px;
    //background: orange;
    display: flex;
    padding-left: calc((100vw - 1000px) / 2);
    padding-right: calc((100vw - 1000px) / 2);

    @media screen and (max-width: 768px){
      height: auto;
    }
`;

export const InfoRow = styled.div`
    width: 100%;
    display: grid;
    //grid-template-areas: 'col1 col2';
    grid-template-columns: 1fr 1fr;
    
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        //grid-template-areas: 'col1' 'col2';
    }
`;

export const Column1 = styled.div`
    //margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    //grid-area: col1;
    //background: green;
`;

export const Column2 = styled.div`
    //margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    //grid-area: col2;
    //background: red;
`;

export const Paragraph = styled.p`
  color: ${props => props.color || '#000'};
  font-size: ${props => props.size || '16px'};
  font-weight: ${props => props.fontWeight || 'lighter'};
  margin-bottom: ${props => props.margin || '0'};

  @media screen and (max-width: 768px){
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const Image = styled.img`
  //width: 100%;
  height: 386px;
  object-fit: cover;
  margin: 5px;

  @media screen and (max-width: 768px){
    height: 340px;
  }
`;