import React from 'react';
import { 
    FooterContainer,
    FooterRow,
    Column1,
    Column2,
    Img 
} from './FooterElements';

const Footer = () => {
    return (
        <>
            <FooterContainer>
                <FooterRow>
                    <Column1>
                        <Img src={require('../../images/footer/logo_footer.svg')} alt='logo'/>
                    </Column1>
                    <Column2>
                        <span>Satc</span>
                    </Column2>
                </FooterRow>
            </FooterContainer>
        </>
    )
}

export default Footer
