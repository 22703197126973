import React from 'react';
import {
    TitleContainer,
    Title,
    InfoContainer,
    InfoRow,
    Column1,
    Column2,
    Paragraph,
    Image
} from './BlockElements';
import Img1 from './graph.svg'
import Img2 from './map.svg'
const Block = (dados) => {
    return (
        <>
            <TitleContainer color={dados.color}>
                <Title>Título do bloco</Title>  
            </TitleContainer>
            <InfoContainer>
                <InfoRow>
                    <Column1>
                        <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam posuere molestie massa at condimentum. Ut in lacinia eros. Sed dignissim semper purus vel mattis. Pellentesque semper eu nisi at consequat. Cras sit amet est felis. Vestibulum porttitor nisl ac pretium laoreet. Duis non justo tempor, congue massa at, dapibus metus. Mauris euismod, libero lobortis consequat euismod, nulla risus dapibus ante, ut porttitor erat leo vel mi. Duis eu dictum purus, non lacinia nisi. Fusce nec placerat sem, vel fringilla mi. Vivamus eget ligula in nibh maximus mollis. Aliquam erat volutpat. Aenean aliquet nibh et mattis molestie. Praesent viverra nibh non ante sollicitudin, in laoreet lacus consequat. Nam ornare eros in urna egestas blandit. Interdum et malesuada fames ac ante ipsum primis in faucibus.</Paragraph>
                    </Column1>
                    <Column2>
                        <Image src={Img1} />
                    </Column2>
                </InfoRow>    
            </InfoContainer>  

            <TitleContainer color={dados.color}>
                <Title>Título do bloco</Title>  
            </TitleContainer>
            <InfoContainer>
                <InfoRow>
                    <Column1>
                        <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam posuere molestie massa at condimentum. Ut in lacinia eros. Sed dignissim semper purus vel mattis. Pellentesque semper eu nisi at consequat. Cras sit amet est felis. Vestibulum porttitor nisl ac pretium laoreet. Duis non justo tempor, congue massa at, dapibus metus. Mauris euismod, libero lobortis consequat euismod, nulla risus dapibus ante, ut porttitor erat leo vel mi. Duis eu dictum purus, non lacinia nisi. Fusce nec placerat sem, vel fringilla mi. Vivamus eget ligula in nibh maximus mollis. Aliquam erat volutpat. Aenean aliquet nibh et mattis molestie. Praesent viverra nibh non ante sollicitudin, in laoreet lacus consequat. Nam ornare eros in urna egestas blandit. Interdum et malesuada fames ac ante ipsum primis in faucibus.</Paragraph>
                    </Column1>
                    <Column2>
                        <Image src={Img2} />
                    </Column2>
                </InfoRow>    
            </InfoContainer>
        </>
    )
}

export default Block
