import React, { useState } from 'react';
//import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
//import '@brainhubeu/react-carousel/lib/style.css';
import Carousel from 'react-items-carousel';

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  SelectCity,
  NavLinkLogo,
  Image,
  ArrowLeft,
  ArrowRight,
  ArrowButton,
  NavBtnContainer,
  NavSpan
} from './NavbarElements';



const Navbar = ({toggle, click, city, setCity}) => {

  const change = (event) => {
    setCity(event.target.value)
  }

  /*const responsive = {
    0: {
      items: 1
    },
    600: {
      items: 5
    },
    1024: {
      items: 7
    }
  };*/
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 35;

  return (
    <>
      <Nav>
        <NavLinkLogo to='/'>
          <img src={require('../../images/logo_menu.svg')} alt='logo' />
        </NavLinkLogo>
        {/*<Image src={require('../../images/ods1.png')} alt='ods1' />*/}
        <Bars onClick={toggle}/>
        <NavMenu>
          
          <Carousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={6}
            gutter={12}
            leftChevron={<ArrowButton><ArrowLeft/></ArrowButton>}
            rightChevron={<ArrowButton><ArrowRight/></ArrowButton>}
            chevronWidth={chevronWidth}
            alwaysShowChevrons
            outsideChevron
          >
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS1")}>
              <Image color="#e5233d" src={require('../../images/navbar/ods1.png')} alt='ods1' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS2")}>
              <Image color="#dda73a" src={require('../../images/navbar/ods2.png')} alt='ods2' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS3")}>
              <Image color="#4ca146" src={require('../../images/navbar/ods3.png')} alt='ods3' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS4")}>
              <Image color="#c7212f" src={require('../../images/navbar/ods4.png')} alt='ods4' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS5")}>
              <Image color="#ef402d" src={require('../../images/navbar/ods5.png')} alt='ods5' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS6")}>
              <Image color="#27bfe6" src={require('../../images/navbar/ods6.png')} alt='ods6' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS7")}>
              <Image color="#fbc412" src={require('../../images/navbar/ods7.png')} alt='ods7' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS8")}>
              <Image color="#a31c44" src={require('../../images/navbar/ods8.png')} alt='ods8' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS9")}>
              <Image color="#f26a2e" src={require('../../images/navbar/ods9.png')} alt='ods9' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS10")}>
              <Image color="#de1768" src={require('../../images/navbar/ods10.png')} alt='ODS 10' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS11")}>
              <Image color="#f89d2a" src={require('../../images/navbar/ods11.png')} alt='ODS 11' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS12")}>
              <Image color="#bf8d2c" src={require('../../images/navbar/ods12.png')} alt='ODS 12' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS13")}>
              <Image color="#407f46" src={require('../../images/navbar/ods13.png')} alt='ODS 13' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS14")}>
              <Image color="#1f97d4" src={require('../../images/navbar/ods14.png')} alt='ODS 14' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS15")}>
              <Image color="#59ba47" src={require('../../images/navbar/ods15.png')} alt='ODS 15' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS16")}>
              <Image color="#136a9f" src={require('../../images/navbar/ods16.png')} alt='ODS 16' />
            </NavLink>
            <NavLink to='/ods' activeStyle={{ color:'red' }} onClick={() => click("ODS17")}>
              <Image color="#14496b" src={require('../../images/navbar/ods17.png')} alt='ODS 17' />
            </NavLink>
          
          </Carousel>
          {/* <TestLink onClick={() => click("ODS 1")}>
            ODS1
          </TestLink>
          <TestLink onClick={() => click("ODS 2")}>
            ODS2
          </TestLink> */}
          
          {/* Second Nav */}
          {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
          
          </NavMenu>  
        
        
        <NavBtnContainer>
          <NavSpan>{city}</NavSpan>
          <NavBtn>
            <SelectCity onChange={change}>
              <option value="" hidden>Cidades</option>
              <option value="Criciúma">Criciúma</option>
              <option value="Tubarão">Tubarão</option>
              <option value="Nova Veneza">Nova Veneza</option>
              <option value="Forquilhinha">Forquilhinha</option>
              <option value="Treviso">Treviso</option>
              <option value="Urussanga">Urussanga</option>
              <option value="Tubarão">Tubarão</option>
              <option value="Laguna">Laguna</option>
              <option value="Florianópolis">Florianópolis</option>
            </SelectCity>
          </NavBtn>
        </NavBtnContainer>
      </Nav>
    </>
  );
};

export default Navbar;
