import React from 'react';

const About = ({text}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh'
      }}
    >
    <h1>Trocar cidade</h1>
    </div>
  );
};

export default About;
