import styled from 'styled-components'

export const HeaderContainer = styled.div`
    //margin-top: 80px;
    background: #fff;//0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 365px; //600px
    position: relative;
    z-index: 1;

    @media screen and (max-width: 768px){
        height: 220px;
    }
`

export const HeaderBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #fff;//232a34;
    //filter: brightness(55%);
`

export const HeaderContent = styled.div`
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: calc((100vw - 1000px) / 2);
    padding-right: calc((100vw - 1000px) / 2);
    padding-bottom: 40px;
`


export const HeaderP = styled.h2`
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    

    @media screen and (max-width: 768px){
        font-size: 18px;
    }

    @media screen and (max-width: 400px){
        font-size: 16px;
        padding-left: 15px;
    }
`