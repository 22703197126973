import React, {useState} from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import ODS from './pages/ods';
import Relatorio from './pages/relatorio';
import { 
  ods1, ods2, ods3, ods4, ods5, ods6, ods7, ods8, ods9,
  ods10, ods11, ods12, ods13, ods14, ods15, ods16, ods17
} from './data/odsData';

var dados = {text: 'As ODS', image: './images/ods/ods1.jpg', alt:'Imagem Padrão'};

function App() {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [city, setCity] = useState('Criciúma');

  
  const handleClick = (ods) => {
    switch(ods){
      case "ODS1": dados = ods1; console.log('case ods 1');console.log(dados);
                   break;
      case "ODS2": dados = ods2; console.log('case ods 2');
                   break;
      case "ODS3": dados = ods3; console.log('case ods 3');
                   break;
      case "ODS4": dados = ods4; console.log('case ods 4');
                   break;
      case "ODS5": dados = ods5; console.log('case ods 5');
                   break;
      case "ODS6": dados = ods6; console.log('case ods 6');
                   break;
      case "ODS7": dados = ods7; console.log('case ods 7');
                   break;
      case "ODS8": dados = ods8; console.log('case ods 8');
                   break;
      case "ODS9": dados = ods9; console.log('case ods 9');
                   break;
      case "ODS10": dados = ods10; console.log('case ods 10');
                   break;
      case "ODS11": dados = ods11; console.log('case ods 11');
                   break;
      case "ODS12": dados = ods12; console.log('case ods 12');
                   break;
      case "ODS13": dados = ods13; console.log('case ods 13');
                   break;
      case "ODS14": dados = ods14; console.log('case ods 14');
                   break;
      case "ODS15": dados = ods15; console.log('case ods 15');
                   break;
      case "ODS16": dados = ods16; console.log('case ods 16');
                   break;
      case "ODS17": dados = ods17; console.log('case ods 17');
                   break;
      default:  dados = "Problema";
    } 
  }
  console.log(dados)
  return (
    <Router>
      <Navbar toggle={toggle} click={handleClick} city={city} setCity={setCity}/>
      <Sidebar isOpen={isOpen} toggle={toggle} click={handleClick} city={city} setCity={setCity}/>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' render={() => <About {...dados}/>} />
        <Route path='/ods' render={() => <ODS {...dados}/>} />
        <Route path='/relatorio' component={Relatorio} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
