import React from 'react';
import Header from '../components/Header';
import Block from '../components/OdsBlock'

const ODS = (dados) => {
  return (
    <>
      <Header {...dados} />
      <Block color={dados.color}/>
    </>
  );
};

export default ODS;
