import styled from 'styled-components';

export const FooterContainer = styled.nav`
  background: #C9C9C9;
  height: 250px;
  display: flex;
  //justify-content: space-between;
  padding-left: calc((100vw - 1000px) / 2);
  padding-right: calc((100vw - 1000px) / 2);
  //z-index: 10;

`;

export const FooterRow = styled.div`
    width: 100%;
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    //align-items: center;
    grid-template-areas: 'col1 col2';
    
    @media screen and (max-width: 768px){
        grid-template-areas: 'col1' 'col2';
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    grid-area: col1;
    //background: green;
    height: 250px;

    @media screen and (max-width: 768px){
      height: auto;
      margin-bottom: 0px;
    }
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    grid-area: col2;
    //background: red;
    height: 250px;

    @media screen and (max-width: 768px){
      height: auto;
      margin-bottom: 0px;
    }
`;

export const Img = styled.img`
  width: 249px;
  height: 78px;

  @media screen and (max-width: 768px){
    width: 220px;
    height: 60px;
  }
`;