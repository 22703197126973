import React from 'react'
import {
    HeaderContainer,
    HeaderBg,
    ImageBg,
    HeaderContent,
    HeaderP
} from './HeaderElements'

const Header = (data) => {
    console.log(data)
    return (
        <HeaderContainer>
            <HeaderBg>
                <ImageBg src={data.image} alt={data.alt}/>
            </HeaderBg>
            <HeaderContent>
                <HeaderP>{data.text}</HeaderP>
            </HeaderContent>
        </HeaderContainer>
    )
}

export default Header
