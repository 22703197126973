import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md'

export const Nav = styled.nav`
  background: #02689D;
  height: 80px;
  display: flex;
  justify-content: space-between;
  //padding: 0.1rem calc((100vw - 1000px) / 2);
  padding-left: calc((100vw - 1000px) / 2);
  padding-right: calc((100vw - 1000px) / 2);
  z-index: 10;

  /* Third Nav */
  /* justify-content: flex-start; */
`;

export const Image = styled.img`
  background: ${props => props.color};
  width: 70px;
  height: 70px;
  margin-top: 5px;
`;

export const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;

export const NavLinkLogo = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 1.5rem;
  //height: 100%;
  cursor: pointer;
  //padding: 0 0.2rem;

  @media screen and (max-width: 768px){
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ArrowLeft = styled(MdKeyboardArrowLeft)`
  font-size: 35px;
  color: #0398da;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  font-size: 35px;
  color: #0398da;
`;

export const ArrowButton = styled.button`
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: #fff;
  text-decoration: none;
  outline: none;
  /*&:active{
    border: none;
    text-decoration: none;
    background: #0398da;
  }*/   
`;

export const TestLink = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    color: #15cdfc;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  //display: flex;
  //align-items: center;
  //margin-left: 10px;
  background: #fff;
  max-width: 535px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavArrowContainer = styled.div`
  width: 15px;
  //height: 80px;
  background: #fff;
`;

export const NavBtnContainer = styled.div`
    max-width: 30%;
    justify-self: flex-center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const NavSpan = styled.span`
  text-align: center;
  font-size: 1.0rem;
  color: #fff;
  margin-bottom: 4px;
  //margin-bottom: 4px;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SelectCity = styled.select`
  width: 120px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px;
  background: #256ce1;
  padding: 4px 12px;
  color: #fff;
  //outline: none;
  border: none;
  cursor: pointer;
  //transition: all 0.2s ease-in-out;
  //text-decoration: none;
  margin-left: 24px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;
