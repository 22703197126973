import React from 'react'
import {
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarRoute,
    SideLinkLogo,
    SideBtnContainer,
    SideSpan,
    SideSelectContainer,
    SideSelect,
    SideLinkContainer,
    SideLink,
    Image,
    InfoTitleContainer,
    InfoTitle
} from './SidebarElements'

const Sidebar = ({isOpen, toggle, click, city, setCity}) => {

    const change = (event) => {
        setCity(event.target.value)
        toggle()
    }

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <SideLinkLogo to='/'>
                <img src={require('../../images/logo_menu_sidebar.svg')} alt='logo' />
            </SideLinkLogo>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <InfoTitleContainer>
                    <InfoTitle>
                        ESCOLHA A ODS QUE DESEJA CONHECER MAIS:
                    </InfoTitle>
                </InfoTitleContainer>
                <SideLinkContainer>
                    <SideLink to='/ods' onClick={() => click("ODS1")}>
                        <Image color="#e5233d" src={require('../../images/navbar/ods1.png')} alt='ods1' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS2")}>
                        <Image color="#dda73a" src={require('../../images/navbar/ods2.png')} alt='ods2' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS3")}>
                        <Image color="#4ca146" src={require('../../images/navbar/ods3.png')} alt='ods3' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS4")}>
                        <Image color="#c7212f" src={require('../../images/navbar/ods4.png')} alt='ods4' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS5")}>
                        <Image color="#ef402d" src={require('../../images/navbar/ods5.png')} alt='ods5' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS6")}>
                        <Image color="#27bfe6" src={require('../../images/navbar/ods6.png')} alt='ods6' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS7")}>
                        <Image color="#fbc412" src={require('../../images/navbar/ods7.png')} alt='ods7' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS8")}>
                        <Image color="#a31c44" src={require('../../images/navbar/ods8.png')} alt='ods8' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS9")}>
                        <Image color="#f26a2e" src={require('../../images/navbar/ods9.png')} alt='ods9' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS10")}>
                        <Image color="#de1768" src={require('../../images/navbar/ods10.png')} alt='ODS 10' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS11")}>
                        <Image color="#f89d2a" src={require('../../images/navbar/ods11.png')} alt='ODS 11' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS12")}>
                        <Image color="#bf8d2c" src={require('../../images/navbar/ods12.png')} alt='ODS 12' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS13")}>
                        <Image color="#407f46" src={require('../../images/navbar/ods13.png')} alt='ODS 13' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS14")}>
                        <Image color="#1f97d4" src={require('../../images/navbar/ods14.png')} alt='ODS 14' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS15")}>
                        <Image color="#59ba47" src={require('../../images/navbar/ods15.png')} alt='ODS 15' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS16")}>
                        <Image color="#136a9f" src={require('../../images/navbar/ods16.png')} alt='ODS 16' />
                    </SideLink>
                    <SideLink to='/ods' onClick={() => click("ODS17")}>
                        <Image color="#14496b" src={require('../../images/navbar/ods17.png')} alt='ODS 17' />
                    </SideLink>
                </SideLinkContainer>

                <SideBtnContainer>
                    <SideSpan>{city}</SideSpan>
                    <SideSelectContainer>
                        <SideSelect onChange={change}>
                            <option value="" hidden>Cidades</option>
                            <option value="Criciúma">Criciúma</option>
                            <option value="Tubarão">Tubarão</option>
                            <option value="Nova Veneza">Nova Veneza</option>
                            <option value="Forquilhinha">Forquilhinha</option>
                            <option value="Treviso">Treviso</option>
                            <option value="Urussanga">Urussanga</option>
                            <option value="Tubarão">Tubarão</option>
                            <option value="Laguna">Laguna</option>
                            <option value="Florianópolis">Florianópolis</option>
                        </SideSelect>
                    </SideSelectContainer>
                </SideBtnContainer>

                <SidebarMenu>
                    <SidebarRoute to='/' onClick={toggle}>
                        HOME
                    </SidebarRoute>
                    <SidebarRoute to='/relatorio' onClick={toggle}>
                        GERAR RELATÓRIO
                    </SidebarRoute>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
