export const ods1 = {
    text: "ODS 1 - Erradicação da Pobreza",
    image: "./images/ods/ods1.jpg",
    color: "#e5233d",
    alt: "ODS 1"
}

export const ods2 = {
    text: "ODS 2 - Fome Zero e Agricultura Sustentável",
    image: "./images/ods/ods2.jpg",
    color: "#dda73a",
    alt: "ODS 2"
}

export const ods3 = {
    text: "ODS 3 - Saúde e Bem-estar",
    image: "./images/ods/ods3.jpg",
    color: "#4ca146",
    alt: "ODS 3"
}

export const ods4 = {
    text: "ODS 4 - Educação de Qualidade",
    image: "./images/ods/ods4.jpg",
    color: "#c7212f",
    alt: "ODS 4"
}

export const ods5 = {
    text: "ODS 5 - Igualdade de Gênero",
    image: "./images/ods/ods5.jpg",
    color: "#ef402d",
    alt: "ODS 5"
}

export const ods6 = {
    text: "ODS 6 - Água Potavel e Saneamento",
    image: "./images/ods/ods6.jpg",
    color: "#27bfe6",
    alt: "ODS 6"
}

export const ods7 = {
    text: "ODS 7 - Energia Limpa e Acessível",
    image: "./images/ods/ods7.jpg",
    color: "#fbc412",
    alt: "ODS 7"
}

export const ods8 = {
    text: "ODS 8 - Trabalho Decente e Crescimento Econômico",
    image: "./images/ods/ods8.jpg",
    color: "#a31c44",
    alt: "ODS 8"
}

export const ods9 = {
    text: "ODS 9 - Industria Inovação e Infraestrutura",
    image: "./images/ods/ods9.jpg",
    color: "#f26a2e",
    alt: "ODS 9"
}

export const ods10 = {
    text: "ODS 10 - Redução das Desigualdades",
    image: "./images/ods/ods10.jpg",
    color: "#de1768",
    alt: "ODS 10"
}

export const ods11 = {
    text: "ODS 11 - Cidades e Comunidades Sustentáveis",
    image: "./images/ods/ods11.jpg",
    color: "#f89d2a",
    alt: "ODS 11"
}

export const ods12 = {
    text: "ODS 12 - Consumo e Produção Responsaveis",
    image: "./images/ods/ods12.jpg",
    color: "#bf8d2c",
    alt: "ODS 12"
}

export const ods13 = {
    text: "ODS 13 - Ação Contra a Mudança Global do Clima",
    image: "./images/ods/ods13.jpg",
    color: "#407f46",
    alt: "ODS 13"
}

export const ods14 = {
    text: "ODS 14 - Vida na Água",
    image: "./images/ods/ods14.jpg",
    color: "#1f97d4",
    alt: "ODS 14"
}

export const ods15 = {
    text: "ODS 15 - Vida Terrestre",
    image: "./images/ods/ods15.jpg",
    color: "#59ba47",
    alt: "ODS 15"
}

export const ods16 = {
    text: "ODS 16 - Paz, Justiça e Instituições eficazes",
    image: "./images/ods/ods16.jpg",
    color: "#136a9f",
    alt: "ODS 16"
}

export const ods17 = {
    text: "ODS 17 - Parcerias e Meios de Implementação",
    image: "./images/ods/ods17.jpg",
    color: "#14496b",
    alt: "ODS 17"
}